import React from "react"
import PropTypes from "prop-types"

import { StyledCodex } from "./Codex.styled"

const Codex = ({ children }) => {
  return <StyledCodex>{children}</StyledCodex>
}

Codex.propTypes = {
  children: PropTypes.node,
}

export default Codex
